import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCLSfwJ17oiPil2lIIvqoOHmYnfOTzHUjE",
    authDomain: "bithon-app-46087.firebaseapp.com",
    databaseURL: "https://bithon-app-46087-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "bithon-app-46087",
    storageBucket: "bithon-app-46087.appspot.com",
    messagingSenderId: "220107128285",
    appId: "1:220107128285:web:79e6da9a22c2a85a690a34",
    measurementId: "G-BJEXHC8B0C"
  };
  

const app = initializeApp(firebaseConfig);

export default app;
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { URLSearchParams } from "url";
import firebaseApp from './../../Firebase';
import { applyActionCode, getAuth } from 'firebase/auth';
import { EmailHandlerInfo, EmailHandlerStatus } from "./email-handler-models";
import { Grid, Box, Typography } from "@mui/material";
import './email-handler.css';

const _verifyEmailBySearchParams = (
    searchParams: URLSearchParams,
    setStatus: React.Dispatch<React.SetStateAction<EmailHandlerInfo>>
) => {

    const mode = searchParams.get("mode");
    const actionCode = searchParams.get("oobCode");

    if (mode !== "verifyEmail" || !actionCode) {
        setStatus(EmailHandlerStatus.ERROR);
        return
    }

    const auth = getAuth(firebaseApp);

    applyActionCode(auth, actionCode)
        .then(() => {
            setStatus(EmailHandlerStatus.SUCCESS);
        })
        .catch(() => {
            setStatus(EmailHandlerStatus.EXPIRED);
        });
}

const style = {
    grid__container: {
        display: { xs: "block", sm: 'block', md: 'flex', xl: 'flex' },
        width: '100vw',
        height: '100vh'
    },
    grid__item__01: {
        height: { xs: '50%', sm: '50%', md: 'auto', xl: 'auto' }
    },
    grid__item_box_01: {
        height: { xs: '100%', sm: '100%', md: 'auto', xl: 'auto' },
        width: { xs: '100vw', sm: '100vw', md: 'auto', xl: 'auto' },
        maxHeight: { xs: '100%', sm: '100%', md: 'auto', xl: 'auto' },
        maxWidth: { xs: '100vw', sm: '100vw', md: 'auto', xl: 'auto' },
        margin: '0px auto',
        display: 'flex',
        flexDirection: 'column-reverse'
    },
    grid__item__02: {
        textAlign: { xs: 'center', sm: 'center', md: 'left', xl:'left'}
    },
    eng_title: {
        fontSize: { xs: "1.2rem", sm: '2rem', md: '2rem', xl: '2rem' },
        fontWeight: '600'
    },
    chi_title: {
        fontSize: { xs: "2rem", sm: '3rem', md: '3rem', xl: '3rem' },
        fontWeight: '600'
    },
    eng_subtitle: {
        fontSize: { xs: "1rem", sm: '2rem', md: '2rem', xl: '2rem' },
        paddingLeft: { xs: "4.5rem", sm: '4.5rem', md: '0px', xl: '0px' },
        paddingRight: { xs: "4.5rem", sm: '4.5rem', md: '0px', xl: '0px' }
    },
    chi_subtitle: {
        fontSize: { xs: "1rem", sm: '2rem', md: '2rem', xl: '2rem' },
        paddingLeft: { xs: "4.5rem", sm: '4.5rem', md: '0px', xl: '0px' },
        paddingRight: { xs: "4.5rem", sm: '4.5rem', md: '0px', xl: '0px' }
    }
}

export const EmailHandler: FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState(EmailHandlerStatus.UNKNOWN);

    let checked = false;

    useEffect(() => {
        if(!checked) {
            _verifyEmailBySearchParams(searchParams, setStatus);
            checked = true;
        }
    }, []);

    return (
        <Grid container alignItems="center" sx={style.grid__container}>
            <Grid item xs={12} sm={12} md={6} xl={6} sx={style.grid__item__01}>
                <Box sx={style.grid__item_box_01}>
                    <img src={status.img} className="image__resize_to_width" />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={style.grid__item__02}>
                <Typography sx={style.eng_title}>{status.eng_title}</Typography>
                <Typography sx={style.chi_title}>{status.chi_title}</Typography>
                <Box my={2}>
                    <Typography sx={style.eng_subtitle}>{status.eng_subtitle}</Typography>
                    <Typography sx={style.chi_subtitle}>{status.chi_subtitle}</Typography>
                </Box>

                <Typography>{status.eng_tips}</Typography>
                <Typography>{status.chi_tips}</Typography>
            </Grid>
        </Grid >
    );
}
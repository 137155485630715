import { icon_confirmed, icon_error, icon_expired } from './images/icons';

export interface EmailHandlerInfo {
    eng_title: string,
    chi_title: string,
    eng_subtitle: string,
    chi_subtitle: string,
    eng_tips: string,
    chi_tips: string,
    btn_text: string,
    img: string
}

export class EmailHandlerStatus {
    static readonly SUCCESS: EmailHandlerInfo = {
        eng_title: "Email Verification Passed",
        chi_title: "已通過電郵驗證",
        eng_subtitle: "Your email has been successfully linked to your account",
        chi_subtitle: "您的電郵已經成功綁定了帳戶",
        btn_text: "Back to BITHON 返回幣坊",
        eng_tips: "Back to BITHON App",
        chi_tips: "返回幣坊App",
        img: icon_confirmed
    }
    static readonly ERROR: EmailHandlerInfo = {
        eng_title: "Error Encountered",
        chi_title: "出現錯誤了",
        eng_subtitle: "The page cannot be displayed or does not exist",
        chi_subtitle: "頁面無法顯示或不存在",
        btn_text: "Back to BITHON 返回幣坊",
        eng_tips: "Back to BITHON App",
        chi_tips: "返回幣坊App",
        img: icon_error
    }
    static readonly EXPIRED: EmailHandlerInfo = {
        eng_title: "Invalid Email Verification",
        chi_title: "電郵驗證已過期或失效",
        eng_subtitle: "Please resend the verification. If you need assistance, please contact the customer service",
        chi_subtitle: "請嘗試重新發送驗證電郵。如需協助，請聯絡客戶服務員",
        btn_text: "Back to BITHON 返回幣坊",
        eng_tips: "Back to BITHON App",
        chi_tips: "返回幣坊App",
        img: icon_expired
    }
    static readonly UNKNOWN: EmailHandlerInfo = {
        eng_title: "loading...",
        chi_title: "",
        eng_subtitle: "",
        chi_subtitle: "",
        eng_tips: "",
        chi_tips: "",
        btn_text: "",
        img: ""
    }

    private constructor() {}
}